<template>
  <div class='text-center py-32 px-8 max-w-lg mx-auto'>
    <i class='el-icon-loading text-6xl mb-4'></i>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import checkNewTokenApi from '@/api/v1/users/checkNewToken'

export default {
  name: 'MobileAppLoginRedirectPage',
  methods: {
    ...mapActions('users', [
      'mobileAppLogin',
    ]),
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
    const additionalParams = {}

    for (const key in this.$route.query) {
      if (
        key !== 'access_token' &&
        key !== 'user_code' &&
        key !== 'user_role' &&
        key !== 'redirect_route'
      ) {
        additionalParams[key] = this.$route.query[key]
      }
    }

    if (this.$route.query.access_token && 
        this.$route.query.user_code && 
        this.$route.query.user_role && 
        this.$route.query.redirect_route) {

      checkNewTokenApi.checkNewToken(this.$route.query.access_token)
        .then(() => {
          this.mobileAppLogin({
            access_token: this.$route.query.access_token,
            user_id: this.$route.query.user_code,
            user_role: this.$route.query.user_role,
          }).then(() => {
            this.$router.replace({name: this.$route.query.redirect_route, query: {...additionalParams, on_mobile_app: true}})
          })
        })
        .catch(error => {
          console.error('check token error: ', error)
          this.$router.replace({name: this.$route.query.redirect_route, query: {...additionalParams, on_mobile_app: true}})
        })
    } else {
      // maybe send login fail message to app
      console.error('query params are off: ', this.$route.query)
    }
  },
}
</script>
