import axios  from 'axios'

const checkNewToken = (newToken) => {
  const base = axios.create({
    baseURL: `${process.env.VUE_APP_API}/api/v1`,
    headers: {
      Authorization: {
        toString () {
          return newToken
        }
      },
      'Content-Type': 'application/json; charset=utf-8',
      'Channel': window.location.host.replace('www.','').split('.')[0]
    }
  })
  
  return base.get(`/users/sso_sessions/check_token`)
             .then(resp => resp)
}


export default {
  checkNewToken: checkNewToken,
}
